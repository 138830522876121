import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import CustomModal from 'Components/Dynamics/Modals'
import Button from 'Components/Dynamics/Button'
import { Message } from 'Utility/Services'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { useForm } from 'react-hook-form'
import { SearchOutlined } from '@ant-design/icons'
import { FormController } from 'Components/FormControl'
import { deleteTestById, getAllTests } from 'API/Services/test.service'
import Loader from 'Components/Loader/Loader'

const TestListing = pagination((props) => {
  const { currentPage, setTotal, setCurrentPage, type } = props
  console.log("🚀 ~ TestListing ~ type:", type)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState(false)
  const Form = useForm()
  const [data, setData] = useState([])
  const [queryFilters, setQueryFilters] = useState({
    text: '',
    date: null
  })
  const fields = [
    { type: 10, name: 'search', state: setQueryFilters, stateKey: "text", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search', placeholder: 'Search test', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
    // {
    //   type: 8, name: 'testType', label: "", state: setQueryFilters, stateKey: "type", rules: { required: false }, label: "Test type", placeholder: 'Search by test type', use: 'antd', span: "col-span-4", className: "w-full", size: "small", mainDivCss: "bg-white h-[40px]",
    //   options: [
    //     { value: 2, title: "Writing Test" },
    //     { value: 1, title: "Reading Test" }]
    // },
    { type: 3, name: 'date', label: "", state: setQueryFilters, stateKey: "date", rules: { required: false }, label: "Filter by date", placeholder: "Select date", use: 'antd', span: "col-span-4", className: "h-[40px] w-full" },
  ]
  const handleModal = (id) => {
    setId(id)
    setModal(!modal)
  }
  const deleteTest = async () => {
    await deleteTestById(id, setModal)
    fetchTests()
  }
  const fetchTests = async () => {
    const result = await getAllTests({}, {
      type: type,
      ...queryFilters,
      offset: currentPage - 1, limit: 1000000000000000
    })
    setTotal(result?.count)
    setData(result?.Tests)
  }
  useEffect(() => {
    fetchTests()
  }, [queryFilters?.date, queryFilters?.text, queryFilters?.type, currentPage,type])
  return (
    <div className='bg-white p-3 rounded-md'>
      <div>
        <Header heading={'Tests'} />
        <Divider className='my-2' />
      </div>
      <FormController
        Form={Form}
        fields={fields}
        onSubmit={() => { }}
        className={'grid grid-cols-12 gap-2'}
      />
      <Loader keys={['getAllTests']}>
        <DynamicTable column={getColumn('testsListing', handleModal)} pagination={false} data={data}
          className={`border border-custom-gray-200  rounded-md mt-3`} />
      </Loader>
      {/* Custom Modal */}
      <CustomModal open={modal} close={() => setModal(!modal)} field={<Button onClick={deleteTest} additionalClassName='mt-[10px] w-full' buttonType='darkRed'>Delete</Button>} />
    </div>
  )
})

export default TestListing