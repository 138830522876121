import { uploadApi } from 'API/Services/generic.service'
import { createN400Question, updateN400Question } from 'API/Services/n400.service'
import { uploadRequest } from 'API/api.methods'
import { ImageImports } from 'Assets/Images'
import Button from 'Components/Dynamics/Button'
import Image from 'Components/Dynamics/Image'
import { FormController, FormService } from 'Components/FormControl'
import { categoriesN400 } from 'Utility'
import { validations } from 'Utility/Validations'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

const CreateMcqQuestion = ({ data = false, close, }) => {
    console.log("🚀 ~ CreateMcqQuestion ~ data:", data)
    const Form = useForm()
    const [audioUrl, setAudioUrl] = useState(false)
    const [formFields, setFormFields] = useState([])

    const handleFile = async (file, type) => {
        const result = await uploadApi([file], 5)
        if (result) setAudioUrl(result)
    }
    const questionField = [
        { type: 1, name: `question`, label: 'Question', rules: { required: true, validate: validations?.checkEmptySpace }, placeholder: 'Write question', use: 'antd', span: "col-span-12", },
        { type: 8, name: `category`, label: 'Category', options: categoriesN400, rules: { required: true }, placeholder: 'Category', use: 'antd', span: "col-span-12", },
        { type: 1, name: `order`, label: 'Order No.', rules: { required: true, validate: validations?.check_decimalNumber }, placeholder: 'Order number', use: 'antd', span: "col-span-12", },
        { type: 102, label: 'Upload Question Audio', name: `audio`, handleFile: handleFile, audioUrl: audioUrl, setaudioUrl: setAudioUrl, span: "col-span-12", audio: data?.audio_url || false, },
    ]
    const initFields = (innerCount = 0, value = '') => {
        return [{ type: 1, divClassName: 'my-2', defaultValue: value, name: `choices[${innerCount}].choice`, label: '', rules: { required: true, validate: validations?.checkEmptySpace }, placeholder: '', use: 'antd', span: "", },]
    }
    const handleField = (type, index, value = '') => {
        let newFields = [...formFields]
        if (type === 'add') {
            newFields.push(initFields(newFields?.length, value))
            setFormFields(newFields)
        } else {
            newFields.splice(index, 1, [])
            setFormFields(newFields)
            unRegisterField(index)
        }
    }
    const unRegisterField = (index) => {
        Form.unregister(`choices[${index}].choice`)
    }
    const hanldeFieldsValue = () => {
        if (data?.Choices?.length !== 0) {
            let newFields = []
            data?.Choices?.forEach((item, index) => {
                const { choice } = item
                newFields.push(initFields(newFields?.length, choice))
            })
            setFormFields(newFields)
        }
    }
    const handleSubmit = async (val) => {
        let body = {
            question: val?.question,
            order: val?.order,
            category: val?.category,
            choices: val?.choices ? val?.choices?.filter(item => item) : [],
            type: 'MCQ',
            audio_url: audioUrl 
        }
        if (!audioUrl) delete body.audio_url
        if (!data) await createN400Question(body)
        else await updateN400Question(data?.id, body)
        close()
    }
    useEffect(() => {
        if (data) {
            setFormFields([])
            Form?.setValue('choices', '[]');
            setAudioUrl(data?.audio_url)
            Form?.setValue('question', data?.question)
            Form?.setValue('order', data?.order)
            Form?.setValue('category', data?.category)

            hanldeFieldsValue()
        } else {
            Form?.setValue('choices', '');
            Form?.setValue('order', '');
            Form?.setValue('question');
            setFormFields([])
            setAudioUrl('')
        }
    }, [data])
    return (
        <div className='border border-custom-gray-300 rounded-md p-3 w-full bg-[white] mt-3'>
            <form onSubmit={Form.handleSubmit(handleSubmit)}>
                <FormService
                    Form={Form}
                    fields={questionField}
                    className={'grid grid-cols-12 gap-2 '}
                />
                {
                    formFields?.map((item, index) => (
                        item.length !== 0 && <div className='w-full gap-2 flex items-center'>
                            <FormService Form={Form}
                                fields={item}
                                className={'grid grid-cols-12 gap-2 w-full my-4'} />
                            <Button additionalClassName='border-none shadow-none ' onClick={() => { handleField('delete', index) }}><Image src={ImageImports?.closeGray} /></Button>
                        </div>
                    ))
                }
                <div className='flex gap-3 my-4'>
                    <Button onClick={() => handleField('add')} additionalClassName='w-full font-semibold text-[11px] shadow-none'><Image src={ImageImports?.addAnother} />{'Add Option'}</Button>
                    <Button additionalClassName='w-full col-span-12' htmlType='submit' buttonType='blue'>
                        {data ? 'Update Form Field' : 'Create Form Field'}
                    </Button>
                </div>
                <Divider />
            </form>

        </div>
    )
}

export default CreateMcqQuestion