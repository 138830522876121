import Button from "../Components/Dynamics/Button"
import { CloudDownloadOutlined, DeleteOutlined, DiscordOutlined, DollarOutlined, DownloadOutlined, EditOutlined, EyeOutlined, FileOutlined, FileTextOutlined, MessageOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
import Image from "../Components/Dynamics/Image"
import { ImageImports } from "../Assets/Images"
import { Link, useNavigate } from "react-router-dom"
import { Checkbox, Rate, Switch } from "antd"
import { DefaultTags } from "../Components/Dynamics/Tags"
import { dateToTimeStampFormat, downloadFile, extractDateFromTimeString, formatMoney, utility } from "Utility"
import { pageRoutes } from "Routing/page.routes"

export const getColumn = (columnType, func, func2) => {
    const columns = {

        adminPatients: [
            {
                title: 'Course Name',
                dataIndex: 'name',
                render: (item, data) => {
                    return <div className='flex items-center gap-2 my-2'>
                        <Image src={ImageImports?.Logo} className={`object-contain  rounded-full`} width={30} />
                        <div className="flex flex-col gap-1">
                            <p className="font-bold text-[12px] text-black">{'Us Visa'}</p>
                        </div>
                    </div>
                }
            },
            {
                title: 'Videos',
                dataIndex: 'empID',
                align: "center",
                render: (item) => {
                    return <p className='text-[10px] text-custom-gray-600 '>20 Videos</p>
                }
            },

            {
                title: 'Created on ',
                dataIndex: 'tag',
                align: "start",
                render: (item) => {
                    return <DefaultTags type='green' label='2nd December 2024' mainDivClassName={'w-fit'} />
                }
            },

            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item) => {
                    return <div className=" flex justify-center w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button additionalClassName=" bg-custom-primary-500 text-[white] text-[14px]" >
                                View Details
                            </Button>
                            <Button additionalClassName=" bg-[white] text-custom-primary-500 text-[14px]" >
                                Edit Course
                            </Button>

                        </div>
                    </div>
                }
            },
        ],
        adminPatientsListing: [
            {
                title: 'Student Name',
                dataIndex: 'name',
                render: (item, data) => {
                    return <div className='flex items-center gap-2 my-2'>
                        <Image src={data?.profile_picture || ImageImports?.noProfilePic} className={`object-contain  rounded-full`} width={40} />
                        <div className="flex flex-col gap-1">
                            <p className=" text-[16px]">{data?.name}</p>
                            <p className=" text-[11px]">{data?.email_address}</p>
                        </div>
                    </div>
                }
            },

            {
                title: 'Subscription Type',
                dataIndex: 'empID',
                align: "center",
                render: (item) => {
                    return <div className="flex justify-center"><DefaultTags type='red' label='Monthly Subscription ' mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Joined On',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center">
                        <DefaultTags type='green' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item, data) => {
                    return <div className=" flex justify-center w-full">
                        <div className=" flex gap-2 flex ">
                            <Button buttonType="lightBlue" >
                                <MessageOutlined />Message
                            </Button>
                            <Button onClick={() => { func(true); func2(data) }} buttonType="blue" >
                                <DollarOutlined />Send Gift
                            </Button>
                        </div>
                    </div>
                }
            },
        ],
        lawyerListing: [
            {
                title: 'Lawyer Name',
                dataIndex: 'name',
                render: (item, data) => {
                    return <div className='flex items-center gap-2 my-2'>
                        <img src={data?.profile_picture || ImageImports?.noProfilePic} className={`object-cover h-[50px] w-[50px]  rounded-full`} width={40} />
                        <div className="flex flex-col gap-1">
                            <p className=" text-[16px]">{data?.name}</p>
                            <p className=" text-[11px]">{data?.email_address}</p>
                        </div>
                    </div>
                }
            },
            {
                title: 'Lawyer-ID',
                dataIndex: 'lawyerId',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='green' label={data?.lawyer_id} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Phone No.',
                dataIndex: 'p.no',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='red' label={data?.phone_number} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'State',
                dataIndex: 'p.no',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='blue' label={data?.state} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Featured',
                dataIndex: 'p.no',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type={data?.featured ? 'whiteBlue':"gray"} label={data?.featured ? 'Featured' : 'Not-Featured'} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Description',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className=" w-[150px] text-[11px]  truncate whitespace-nowrap">
                        {data?.description || "N-A"}
                    </div>
                }
            },
            {
                title: 'Student Refered',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <p className="text-[11px]">{data?.referrals} Students</p>
                }
            },

            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item, data) => {
                    return <div className=" flex justify-center w-full">
                        <div className=" flex gap-2 flex ">
                            {/* <Button onClick={() => { func('invoice'); func2(data) }} buttonType="lightBlue" >
                                <FileTextOutlined />   Invoices
                            </Button> */}
                            <Button onClick={() => { func('add'); func2(data) }}>
                                <EditOutlined className="text-custom-primary-500" />
                            </Button>
                            <Button onClick={() => { func('delete'); func2(data) }}>
                                <DeleteOutlined className="text-[red]" />
                            </Button>
                        </div>
                    </div>
                }
            },
        ],
        lawyerInvoiceListing: [
            {
                title: 'Invoice Date',
                dataIndex: 'p.no',
                align: "center",
                render: (item) => {
                    return <div className="flex justify-center"><DefaultTags type='green' label='23-10-2000 ' mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Download Invoice',
                dataIndex: 'p.no',
                align: "center",
                render: (item) => {
                    return <div className="flex justify-center">
                        <Button buttonType="lightBlue">
                            Downlaod <DownloadOutlined />
                        </Button>
                    </div>
                }
            },



        ],
        adminDashboardVideos: [
            {
                title: 'Course Name',
                dataIndex: 'name',
                render: (item, data) => {
                    return <div className='flex items-center gap-2 my-2'>
                        <img src={data?.cover_image || ImageImports?.Logo} className={`object-cover  rounded-full h-[50px] w-[50px] shadow-md`} />
                        <div className="flex flex-col gap-1">
                            <p className=" text-[16px] ">{data?.name}</p>
                        </div>
                    </div>
                }
            },
            {
                title: 'Videos',
                dataIndex: 'empID',
                align: "center",
                render: (item, data) => {
                    return <p className='text-[10px] text-custom-gray-600 '>{data?.Course_Items?.length}</p>
                }
            },

            {
                title: 'Created on ',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <DefaultTags type='green' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                }
            },
            {
                title: 'Fees ',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <DefaultTags type='whiteBlue' label={formatMoney(data?.fee)} mainDivClassName={'w-fit'} />
                }
            },
            {
                title: 'Course Type ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type={data?.type == 'Free' ? 'green' : 'red'} label={`${data?.type} Course`} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

        ],
        //Courses
        courseListing: [
            {
                title: 'Course Name',
                dataIndex: 'name',
                render: (item, data) => {
                    return <div className='flex items-center gap-2 my-2'>
                        <img src={data?.cover_image || ImageImports?.Logo} className={`object-cover  rounded-full h-[50px] w-[50px] shadow-md`} />
                        <div className="flex flex-col gap-1">
                            <p className=" text-[16px] ">{data?.name}</p>
                        </div>
                    </div>
                }
            },
            {
                title: 'Videos',
                dataIndex: 'empID',
                align: "center",
                render: (item, data) => {
                    return <p className='text-[10px] text-custom-gray-600 '>{data?.Course_Items?.length}</p>
                }
            },

            {
                title: 'Created on ',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <DefaultTags type='green' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                }
            },
            {
                title: 'Course Type ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type={data?.type == 'Free' ? 'green' : 'red'} label={`${data?.type} Course`} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Fee ',
                dataIndex: 'fee',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags label={data?.fee ? formatMoney(data?.fee) : 'N-A'} type={`whiteBlue`} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item, data) => {
                    return <div className=" flex justify-end w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button buttonType="lightBlue" onClick={() => func('add', 'editCourse', data)}  >
                                <EditOutlined />  Edit
                            </Button>
                            <Button onClick={() => { utility?.navigate(`/manage-quiz/${data?.id}`) }} buttonType="lightBlue" additionalClassName=" bg-[white] text-black text-[14px]" >
                                <DiscordOutlined />   Quiz
                            </Button>
                            <Button buttonType='blue' onClick={() => { func('manageMaterial', 'manageMaterial', data); func2(data?.id) }}  >
                                <VideoCameraAddOutlined /> Videos
                            </Button>
                            <Button buttonType='blue' onClick={() => { func('manageDocument', 'manageDocument', data); func2(data?.id) }}  >
                                <FileOutlined />  Docs
                            </Button>
                            <Button onClick={() => func('delete', '', data)} additionalClassName=" bg-[white] text-black text-[14px] border-none" >
                                <DeleteOutlined className="text-[red]" />
                            </Button>
                        </div>
                    </div>
                }
            },
        ],
        quizListing: [
            {
                title: 'Question Name',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <p className='text-[10px] text-custom-gray-600 truncate font-bold '>{data?.question}</p>
                }
            },
            {
                title: 'Created At ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='red' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item, data) => {
                    return <div className=" flex justify-center w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button onClick={() => {
                                func('add', data)
                            }} additionalClassName=" bg-[white] text-black text-[14px]" >
                                Edit Question
                            </Button>
                            <Button onClick={() => {
                                func('delete', data, 'delete')
                            }} additionalClassName=" bg-[white] text-black text-[14px] border-none"  >
                                <DeleteOutlined className="text-[red]" />
                            </Button>
                        </div>
                    </div>
                }
            },
        ],
        courseMaterial: [
            {
                title: 'Video Title ',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <p className='text-[10px] text-custom-gray-600 truncate font-bold w-[200px]'>{data?.name}</p>
                }
            },
            {
                title: 'Created At ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='red' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Video Link',
                dataIndex: 'empID',
                align: "center",
                render: (item, data) => {
                    return <a href="www.facebook.com" className='text-[10px] text-custom-primary-500 '>{data?.video_url}</a>
                }
            },
            {
                title: 'Order No.',
                dataIndex: 'empID',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='whiteBlue' label={`${data?.order}`} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item, data) => {
                    return <div className=" flex justify-end w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button onClick={() => {
                                func('manageMaterial');
                                func('addVideo', 'editVideo', data)
                            }} additionalClassName=" bg-[white] text-black text-[14px]" >
                                Edit Video
                            </Button>
                            <Button onClick={() => {
                                func('manageMaterial');
                                func('delete', 'deleteVideo', data)
                            }} additionalClassName=" bg-[white] text-black text-[14px] border-none" >
                                <DeleteOutlined className="text-[red]" />
                            </Button>
                        </div>
                    </div>
                }
            },
        ],
        courseDocuments: [
            {
                title: 'S.No',
                dataIndex: 'tag',
                align: "start",
                render: (item, data, index) => {
                    return <p className='text-[10px] text-custom-gray-600 truncate font-bold '>{index + 1}</p>
                }
            },
            {
                title: 'Document Title',
                dataIndex: 'empID',
                align: "center",
                render: (item, data) => {
                    return <p  className='text-[10px] text-custom-primary-500 '>{data?.title}</p>
                }
            },
            {
                title: 'Created At ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='red' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Document',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <Button buttonType="blue"
                        onClick={() => { downloadFile(data) }}
                        additionalClassName=" bg-[white] text-black text-[14px]" >
                        Download Document
                    </Button>
                }
            },
            {
                title: 'Action',
                dataIndex: 'actions',
                align: "center",
                render: (item, data) => {
                    return <div className=" flex justify-end w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button onClick={() => {
                                func('manageDocument');
                                func('addDocument', 'editDocument', data)
                            }} additionalClassName=" bg-[white] text-black text-[14px]" >
                                Edit Video
                            </Button>
                            <Button onClick={() => {
                                func('manageDocument');
                                func('delete', 'deleteDocument', data)
                            }} additionalClassName=" bg-[white] text-black text-[14px] border-none" >
                                <DeleteOutlined className="text-[red]" />
                            </Button>

                        </div>
                    </div>
                }
            },
        ],
        //Payments
        paymentListing: [
            {
                title: 'Student',
                dataIndex: 'name',
                render: (item, data) => {
                    return <div className='flex items-center gap-2 my-2'>
                        <Image src={ImageImports?.Avatar || ImageImports?.noProfilePic} className={`object-contain  rounded-full`} width={50} />
                        <div className="flex flex-col gap-1">
                            <p className=" text-[14px] font-semibold">{'Tony Sutton'}</p>
                        </div>
                    </div>
                }
            },
            {
                title: 'Package',
                dataIndex: 'tag',
                align: "start",
                render: (item) => {
                    return <DefaultTags type='green' label='Vip' mainDivClassName={'w-fit'} />
                }
            },
            {
                title: 'Price ',
                dataIndex: 'tag',
                align: "center",
                render: (item) => {
                    return <div className="flex justify-center"><DefaultTags type='whiteBlue' label={formatMoney(200)} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Purchased Of ',
                dataIndex: 'tag',
                align: "center",
                render: (item) => {
                    return <div className="flex justify-center"><DefaultTags type='gray' label='Course USA ' mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Purchased On ',
                dataIndex: 'tag',
                align: "center",
                render: (item) => {
                    return <div className="flex justify-center"><DefaultTags type='blue' label='23rd dec 2024' mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Download Invoice',
                dataIndex: 'actions',
                align: "end",
                render: (item) => {
                    return <div className=" flex justify-end w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button additionalClassName=" bg-white text-black font-semibold text-[14px] rounded-lg" >
                                <CloudDownloadOutlined className="text-custom-primary-500" />    Download
                            </Button>


                        </div>
                    </div>
                }
            },
        ],
        // Tests
        testsListing: [
            {
                title: 'Question',
                dataIndex: 'name',
                render: (item, data) => {
                    return <p className=" text-[16px] truncate w-[200px] ">{data?.text}</p>
                }
            },
            {
                title: 'Category',
                dataIndex: '',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type={data?.type == 1 ? 'green' : "red"} label={data?.type == 1 ? 'Reading Test' : "Writing Test"} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Order-No',
                dataIndex: '',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type={'purple'} label={data?.order || '0'} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
            {
                title: 'Audio',
                dataIndex: '',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center">
                        {
                            data?.audio_url ? <audio controls autoplay >
                                <source src={data?.audio_url} />
                            </audio> : 'N-A'
                        }
                    </div>
                }
            },
            {
                title: 'Created On ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='blue' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                    </div>
                }
            },

            {
                title: 'Actions',
                dataIndex: 'actions',
                align: "end",
                render: (item, data) => {
                    return <div className=" flex justify-end w-full">
                        <div className=" flex gap-2 flex justify-center">
                            <Button onClick={() => { utility?.navigate(`/edit-test/${data?.id}`) }} additionalClassName=" bg-[white] text-black text-[14px]" >
                                <EditOutlined />    Edit Test
                            </Button>
                            <Button onClick={() => func(data?.id)} additionalClassName="text-[red] text-[14px]" >
                                <DeleteOutlined />
                            </Button>
                        </div>
                    </div>
                }
            },
        ],
        //Announcements
        announcementListing: [
            {
                title: 'Title',
                dataIndex: 'name',
                render: (item, data) => {
                    return <p className=" text-[16px] font-semibold">{data?.title}</p>
                }
            },
            {
                title: 'Desc',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <p className=" text-[13px] w-[200px] truncate  text-custom-gray-500 ">{data?.description}</p>
                }
            },
            {
                title: 'External Link',
                dataIndex: 'tag',
                align: "start",
                render: (item, data) => {
                    return <a href={data?.external_link || ''} className="text-custom-blue-700 text-[11px]">{data?.external_link || "N-A"}</a>
                }
            },
            {
                title: 'Created On ',
                dataIndex: 'tag',
                align: "center",
                render: (item, data) => {
                    return <div className="flex justify-center"><DefaultTags type='blue' label={extractDateFromTimeString(data?.created_at, 'words')} mainDivClassName={'w-fit'} />
                    </div>
                }
            },
        ],
    }
    return columns[columnType]
}