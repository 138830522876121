export const pageRoutes = {
    login: '/',
    deleteAccount: '/instruction',
    dashboard: {
        home: '/home',
        howToUseApp: "/howtouseapp",
        settings: '/settings',
        n400: '/n400',
        notFound: "*",
        Users: {
            userListing: '/users',
            // editUser: '/edit-user',
            // createUser: '/create-user',
            // userDetail: '/user-detail'
        },
        Lawyer: {
            lawyerListing: "/lawyer-listing"
        },
        Tests: {
            editTest: '/edit-test/:id',
            createTest: '/create-test',
            readingTest: '/reading-test',
            writingTest: '/writing-test',
            testDetail: '/test-detail'
        },
        Courses: {
            editCourse: '/edit-course/:id',
            createCourse: '/create-course',
            courseListing: '/courses',
            courseDetail: '/course-detail/:id',
            manageQuiz: "/manage-quiz/:id"
        },
        Payment: {
            payments: '/payments',
        },
        chat: {
            chat: "/chat"
        },
        announcement: {
            createAnnouncement: '/create-announcement',
            editAnnouncement: '/announcement-detail/:id',

            allAnnouncement: '/all-announcement'
        }
    }
}