export const validations = {
    required: { required: true },
    email: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
    },
    number: {
        value: /^\d+$/i,
        message: 'Please enter a valid number'
    },
    phoneNumber: {
        value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
        message: "Invalid Phone Number"
    },
    full_name: (value) => {
        if (!value) return undefined
        let regexes = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/i
        if (!regexes?.test(value)) return `Fullname should contain only alphabets!`
        return undefined

    },
    check_email: (value) => {
        if (!value) return undefined
        let regexes = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        if (!regexes?.test(value)) return `Invalid email address!`
        return undefined
    },
    checkEmptySpace: (value) => {
        if (!value) return undefined
        let regexes = /^(?!\s+$).+/
        if (!regexes?.test(value)) return `Kindly enter value`
        return undefined
    },
    check_number: (value) => {
        if (!value) return undefined
        let regexes = /^\d+$/i
        if (!regexes?.test(value)) return `Please enter digits!`
        return undefined
    },
    check_decimalNumber: (value) => {
        if (!value) return undefined
        let regexes = /^\d+(\.\d+)?$/i
        if (!regexes?.test(value)) return `Please enter digits!`
        return undefined
    },
    check_phonenumber: (value) => {
        if (!value) return undefined
        let regexes = /^[0-9]+$/i
        if (!regexes?.test(value)) return `Invalid phone number!`
        return undefined
    },
    password: (value) => {
        let regexes = {
            whitespace: /^\S*$/,
            oneDigit: /^(?=.*[0-9]).*$/,
            uppercase: /^(?=.*[A-Z]).*$/,
            lowercase: /^(?=.*[a-z]).*$/,
            special: /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/,
        }
        if (value?.length < 8) return 'Field must be at least 8 characters long'
        if (!regexes?.whitespace?.test(value)) return `Password must not contain whitespaces!`
        if (!regexes?.oneDigit?.test(value)) return `Password must contain at least one digit!`
        if (!regexes?.uppercase?.test(value)) return `Password must contain at least one uppercase letter!`
        if (!regexes?.lowercase?.test(value)) return `Password must contain at least one lowercase letter!`
        if (!regexes?.special?.test(value)) return `Password must contain at least one special symbol!`
        return undefined
    },
  comma_separated: (value) => {
        if (!value) return undefined
        let regex = /^(\s*[^,\s]+(?:\s+[^,\s]+)*)(?:\s*,\s*(\s*[^,\s]+(?:\s+[^,\s]+)*))*\s*$/
        if (!regex?.test(value)) return `List should be comma separated!`
        return undefined
    }
}
