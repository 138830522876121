
import { catchAsyncErrors } from 'API/Handlers';
import { deleteRequest, getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';

const getAllTests = catchAsyncErrors(async (query, body) => {
    utility.dispatch(setPageLoader({ page: 'getAllTests', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(apiRoutes?.tests?.getAllTest, query),
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'getAllTests', flag: false }))
    return result
})
const getTestById = catchAsyncErrors(async (id, query) => {
    utility.dispatch(setPageLoader({ page: 'getTestById', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(`${apiRoutes?.tests?.getTestById}${id}`, query),
    })
    utility.dispatch(setPageLoader({ page: 'getTestById', flag: false }))
    return result?.Tests
})
const createTest = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'createTest', flag: true }))
    let result = await postRequest({
        endPoint: apiRoutes?.tests?.createTest,
        body: body
    })
    Message?.success(result?.Message)
    utility?.navigate(body?.type == 1 ? pageRoutes?.dashboard?.Tests?.readingTest : pageRoutes?.dashboard?.Tests?.writingTest)
    utility.dispatch(setPageLoader({ page: 'createTest', flag: false }))
    return result
})

const addTutorial = catchAsyncErrors(async (body) => {
    utility.dispatch(setPageLoader({ page: 'addTutorial', flag: true }))
    let result = await postRequest({
        endPoint: apiRoutes?.tutorial?.createTutorial,
        body: body
    })
    Message?.success(result?.Message)
    // utility?.navigate(pageRoutes?.dashboard?.Tests?.testListing)
    utility.dispatch(setPageLoader({ page: 'addTutorial', flag: false }))
    return result
})

const updateTest = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'updateTest', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams(`${apiRoutes?.tests?.updateTest}${id}`,),
        body: body
    })
    Message?.success(result?.Message)
    utility?.navigate(body?.type == 1 ? pageRoutes?.dashboard?.Tests?.readingTest : pageRoutes?.dashboard?.Tests?.writingTest)

    utility.dispatch(setPageLoader({ page: 'updateTest', flag: false }))
    return result
})
const deleteTestById = catchAsyncErrors(async (id, close) => {
    utility.dispatch(setPageLoader({ page: 'deleteTestById', flag: true }))
    let result = await deleteRequest({
        endPoint: addQueryParams(`${apiRoutes?.tests?.deleteTest}${id}`),
    })
    Message?.success(result?.Message)
    close && close(false)
    utility.dispatch(setPageLoader({ page: 'deleteTestById', flag: false }))
    return result
})

export { addTutorial, getAllTests, getTestById, createTest, deleteTestById, updateTest }